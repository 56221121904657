.App {
  text-align: center;
}

.App.pt-64 {
  padding-top: 64px;
}

.App.pt-128 {
  padding-top: 128px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiAutocomplete-inputRoot-440[class*='MuiOutlinedInput-root'] {
  padding: 8px;
  width: 99.5% !important;
  padding-left: 0 !important;
}

@media print {
  .hide-print {
    display: none !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  cursor: pointer;
}

/**
* Bug 
* Google translate gera um bug que fica aparecendo texto aleatorio no input
*/
fieldset legend font {
  display: none;
}

iframe#launcher {
  margin: 0 0 22px 2px !important;
}

strong {
  font-weight: 500;
}

.d3plus-Path {
  cursor: pointer;
  transition: color 300ms;
}

.state .d3plus-Path:hover {
  fill: rgb(45, 193, 208) !important;
}

.AC .d3plus-id-AC,
.AL .d3plus-id-AL,
.AP .d3plus-id-AP,
.AM .d3plus-id-AM,
.BA .d3plus-id-BA,
.CE .d3plus-id-CE,
.DF .d3plus-id-DF,
.ES .d3plus-id-ES,
.GO .d3plus-id-GO,
.MA .d3plus-id-MA,
.MT .d3plus-id-MT,
.MS .d3plus-id-MS,
.MG .d3plus-id-MG,
.PA .d3plus-id-PA,
.PB .d3plus-id-PB,
.PR .d3plus-id-PR,
.PE .d3plus-id-PE,
.PI .d3plus-id-PI,
.RJ .d3plus-id-RJ,
.RN .d3plus-id-RN,
.RS .d3plus-id-RS,
.RO .d3plus-id-RO,
.RR .d3plus-id-RR,
.SC .d3plus-id-SC,
.SP .d3plus-id-SP,
.SE .d3plus-id-SE,
.TO .d3plus-id-TO {
  stroke:  rgb(83 164 172) !important;
  fill: rgb(45, 193, 208) !important;
}